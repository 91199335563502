import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as api from '../../../../api';
import DatePicker from 'react-date-picker';
import { setAlert, setLoading, setPartnerPreviewPage, setVehiclePreviewPage } from '../../../../globalState/action-creators';
import Select from '../../../../components/bootstrap/forms/Select';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Button from '../../../../components/bootstrap/Button';
import Page from '../../../../layout/Page/Page';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../../components/bootstrap/Card';


const BookMoke = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const tz = moment.tz.guess();

  const token = localStorage.getItem('token')

  const [vehicles, setVehicles] = useState([])
  const [reservationData, setReservationData] = useState([])
  console.log(reservationData)

  const [formData, setFormData] = useState({})
  const [vehiclesData, setVehiclesData] = useState({})
  const [renter, setRenter] = useState()
  const [partner, setPartner] = useState()
  const [partnersData, setpartnersData] = useState([])
  const [currentPartner, setCurrentPartner] = useState("")
  const [currentVehicle, setCurrentVehicle] = useState("")

  const [oldBookingDate, setOldBookingDate] = useState("")
  const [oldBookingEndDate, setOldBookingEndDate] = useState("")

  const [oldBookingTime, setOldBookingTime] = useState("")
  const [oldBookingEndTime, setOldBookingEndTime] = useState("")

  const [tempBookingDate, setTempBookingDate] = useState("")

  const [bd, setBD] = useState("")
  const [bEndDate, setBEndDate] = useState("")

  const [bt, setBT] = useState("")
  const [bEndTime, setBEndTime] = useState("")

  const BOOKING_METHOD = [
    { value: "hourly", text: "Per Hour" },
    { value: "daily", text: "Per Day" },
    { value: "weekly", text: "Per Week" },
    { value: "monthly", text: "Per Monthly" }
  ]

  const fetchReservation = async () => {
    try {
      const { data } = await api.getReservationById(id);
      if (data?.success) {
        const { partnerId, vehicleId, renterId, bookingMethod, bookingDate, bookingEndDate, bookingTime, bookingEndTime, partner } = data?.reservation;
        setReservationData(data?.reservation)
        fetchVehicles(data?.reservation?.partnerId)
        setRenter([{ value: renterId, text: data?.reservation?.renter?.firstName + " " + data?.reservation?.renter?.lastName }])
        setPartner([{ value: partnerId, text: data?.reservation?.partner?.partnerName }])
        setFormData({
          ...formData,
          partnerId: partnerId,
          partnerName: partner?.partnerName,
          vehicleId: vehicleId,
          renterId: renterId,
          bookingMethod: bookingMethod,
          bookingDate: bookingDate,
          bookingEndDate: bookingEndDate,
          bookingTime: bookingTime,
          bookingEndTime: bookingEndTime
        })
      }
    } catch (error) {
      dispatch(setAlert(error?.message, "Error"))
    }
  }

  // VEHICLES
  const fetchVehicles = async partnerId => {
    dispatch(setLoading(true));
    try {
      const { data } = await api.getVehiclesByPartner({ partnerId })
      if (data?.success) {
        setVehiclesData(data?.vehicles);
        setVehicles(
          _.get(data, "vehicles", vehicles)?.length > 0 && _.get(data, "vehicles", vehicles)?.map((vehicle) => {
            return { value: vehicle?._id, text: `${vehicle?.vehicleName} ${vehicle?.licensePlates}` }
          })
        );
      }
      else {
        dispatch(setAlert(data?.message, "Error"))
      }
    } catch (error) {
      dispatch(setAlert(error?.message, "Error"))
    }
    dispatch(setLoading(false));
  }

  // PARNTERS
  const fetchPartners = async () => {
    dispatch(setLoading(true));
    try {
      const { data } = await api.getPartners("isDisabled=false");
      if (data?.success) {
        const optionArray = []
        _.get(data, 'partners', []).map((partner, i) => {
          optionArray.push({ value: partner?._id, text: partner?.partnerName })
          return null
        })
        setpartnersData(optionArray)
      }
    } catch (error) {
      dispatch(setAlert(error?.message, "Error"))
    } finally {
      dispatch(setLoading(false));
    }
  };

  const updateReservation = async reservationId => {
    dispatch(setLoading(true))
    try {
      let bookingDate = moment.utc(bd).toISOString()
      let bookingEndDate = moment.utc(bEndDate).toISOString()
      const bookingTime = moment.utc(bt).toISOString()
      const bookingEndTime = moment.utc(bEndTime).toISOString()

      const isModifyReservationFlow = bookingDate !== oldBookingDate || bookingEndDate !== oldBookingEndDate || bookingTime !== oldBookingTime || bookingEndTime !== oldBookingEndTime

      let updateReservationPayload = {
        ...formData,
        bookingDate,
        bookingEndDate,
        bookingTime,
        bookingEndTime,
      }

      if (isModifyReservationFlow) {
        bookingDate = bookingTime !== oldBookingTime ? bookingTime : bookingDate;
        bookingEndDate = bookingEndTime !== oldBookingEndTime ? bookingEndTime : bookingEndDate;

        updateReservationPayload = {
          ...updateReservationPayload,
          bookingDate,
          bookingEndDate,
          oldBookingDate,
          oldBookingEndDate,
          isModifyReservationFlow
        }
      }

      console.log("UPDATE RESERVATION PAYLOAD", updateReservationPayload);

      const { data } = await api.updateReservation(reservationId, updateReservationPayload);
      if (data?.success) {
        navigate("/reservation", { replace: true });
        dispatch(setAlert(data?.message, "Success"));
      }
      else {
        dispatch(setAlert(data?.message, "Error"))
      }
    }
    catch (error) {
      dispatch(setAlert(error?.message, "Error"))
    }
    dispatch(setLoading(false))
  }

  const onPartnerChange = async (e) => {
    const partnerId = e?.target?.value

    if (!partnerId) {
      return;
    }

    setCurrentPartner(partnerId);
    setFormData(
      {
        ...formData,
        updatedPartnerId: partnerId,
      }
    );
    fetchVehicles(partnerId)
  }

  useEffect(async () => {
    if (!token) {
      navigate('/auth-pages/login', { replace: true })
    }
    dispatch(setPartnerPreviewPage({ partnerPage: 1 }))
    dispatch(setVehiclePreviewPage({ vehiclePage: 1 }))

    await Promise.allSettled([
      fetchReservation(),
      fetchPartners()
    ])
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const currentPartnerId = _.get(formData, 'partnerId', '')

    if (!currentPartnerId) {
      return;
    }

    setCurrentPartner(currentPartnerId);
  }, [_.get(formData, 'partnerId', '')]);

  useEffect(() => {
    if (!vehiclesData?.length) {
      return;
    }
    
    const reservationPartnerId = _.get(formData, 'partnerId', '');
    
    if (currentPartner !== reservationPartnerId) {
      setCurrentVehicle(vehiclesData[0]?._id);
    } else {
      setCurrentVehicle(_.get(formData, "vehicleId", "")?.length > 0 ? _.get(formData, "vehicleId", "") : "Na");
    }
  }, [vehiclesData]);

  const onVehicleChange = (e) => {
    const vehicleId = e?.target?.value

    if (!vehicleId) {
      console.log("No Vehicle ID");
      return;
    }

    setCurrentVehicle(vehicleId);

    setFormData(
      {
        ...formData,
        updatedVehicleId: vehicleId,
      }
    );
  }

  useEffect(() => {
    if (!currentVehicle) {
      return;
    }

    setFormData(
      {
        ...formData,
        updatedVehicleId: currentVehicle,
      }
    );

  }, [currentVehicle]);


  useEffect(() => {
    if (!currentPartner) {
      return;
    }

    setFormData(
      {
        ...formData,
        updatedPartnerId: currentPartner,
      }
    );
  }, [currentPartner]);

  const getEndDateBasedOnStartDate = (startDate, timeDifference) => {
    return moment(startDate).add(timeDifference, 'milliseconds').format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

  const generateNewDateWithProperHours = (date) => {
    const oldStartDate = new Date(oldBookingDate);
    const startDate = new Date(date);

    let now_utc = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(),
                    startDate.getDate(), oldStartDate.getUTCHours(),
                    oldStartDate.getUTCMinutes(), oldStartDate.getUTCSeconds());

    return moment(new Date(now_utc)).toISOString();
  }


  const getTimeDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startUTC = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds());
    const endUTC = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), end.getHours(), end.getMinutes(), end.getSeconds());
    return endUTC - startUTC;
  }

  const bookingTimeChange = (time) => {
    const newStartTime = time.format("YYYY-MM-DDTHH:mm:ss.SSS")

    const timeDifference = getTimeDifference(oldBookingTime, oldBookingEndTime);
    const newEndTime = getEndDateBasedOnStartDate(newStartTime, timeDifference);

    setBT(time);
    setBEndTime(moment(newEndTime).tz(tz))
  }

  const bookingDateChange = (date) => {
    const newBookingDate = generateNewDateWithProperHours(date);
    setTempBookingDate(newBookingDate);
    
    setBT(moment(newBookingDate).tz(tz))
    setBD(new Date(newBookingDate));
  }

  useEffect(() => {
    if (!bd) {
      return;
    }
    const timeDifference = getTimeDifference(oldBookingDate, oldBookingEndDate);
    const newEndDate = getEndDateBasedOnStartDate(tempBookingDate, timeDifference);

    setBEndDate(new Date(newEndDate));
  }, [tempBookingDate]);

  useEffect(() => {
    const bookingDate = _.get(formData, `bookingDate`, "")
    const bookingEndDate = _.get(formData, `bookingEndDate`, "")
    const bookingTime = _.get(formData, `bookingTime`, "")
    const bookingEndTime = _.get(formData, `bookingEndTime`, "")

    if (!bookingDate || !bookingEndDate || !bookingTime || !bookingEndTime) {
      return;
    }

    setOldBookingDate(bookingDate);
    setOldBookingEndDate(bookingEndDate);

    setOldBookingTime(bookingTime)
    setOldBookingEndTime(bookingEndTime)

    setBT(moment(bookingDate).tz(tz))
    setBEndTime(moment(bookingEndDate).tz(tz))

    setBD(new Date(bookingDate))
    setBEndDate(new Date(bookingEndDate))
  }, [formData]);

  return (
    <>
      <PageWrapper title={"Book Moke"}>
        <Page container='fluid'>
          <div className="row">
            <div className="col-xxl-6">
              <Card stretch className="book-moke-card">
                <CardHeader>
                  <CardLabel icon='BookMoke' iconColor="dark">
                    <CardTitle tag='h4' className='h5'>
                      Book Moke
                    </CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className='table-responsive align-center'>
                  {
                    <>
                      <div className='create-vehicle-wrapper vehicle-wrapper align_table'>
                        <div className="card-info-item card-inner">
                          <div className="label">
                            <p class="editrate-text lable_spacing">Partner <span class="text-danger fw-bold">*</span></p>
                          </div>
                          <div className="data">
                            <Select
                              ariaLabel='Default select example'
                              placeholder=' '
                              value={currentPartner}
                              id="partnerId"
                              style={{ boxShadow: 'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset' }}
                              name="partnerId"
                              onChange={(e) => {
                                onPartnerChange(e)
                              }}
                              list={partnersData}
                              className="addVehicleSelect inputBoxShadow"
                            />
                          </div>
                        </div>
                        <div className="card-info-item card-inner">
                          <div className="label">
                            <p class="editrate-text lable_spacing">Vehicle <span class="text-danger fw-bold">*</span></p>
                          </div>
                          <div className="data">
                            <Select
                              ariaLabel='Default select example'
                              className="addVehicleSelect inputBoxShadow"
                              placeholder=' '
                              value={currentVehicle}
                              name="vehicleId"
                              list={vehicles?.length > 0 ? vehicles : [{ value: "", text: "" }]}
                              onChange={onVehicleChange}
                            />
                          </div>
                        </div>
                        <div className="card-info-item card-inner card-height">
                          <div className="label">
                            <p class="editrate-text lable_spacing">Renter<span class="text-danger fw-bold">*</span></p>
                          </div>
                          <div className="data">
                            <Select
                              ariaLabel='Default select example'
                              className="addVehicleSelect inputBoxShadow"
                              placeholder=' '
                              id="renterId"
                              disabled
                              value={_.get(formData, "renterId", "")?.length > 0 ? _.get(formData, "renterId", "") : "Na"}
                              name="renterId"
                              list={renter && renter?.length > 0 ? renter : [{ value: "", text: "" }]}
                            />
                          </div>
                        </div>
                        <div className="card-info-item card-inner card-height">
                          <div className="label">
                            <p class="editrate-text lable_spacing">Booking Period <span class="text-danger fw-bold">*</span></p>
                          </div>
                          <div className="data">
                            <Select
                              ariaLabel='Default select example'
                              className="addVehicleSelect inputBoxShadow"
                              placeholder=' '
                              name="bookingMethod"
                              disabled
                              value={_.get(formData, "bookingMethod", "")?.length > 0 ? _.get(formData, "bookingMethod", "") : "Na"}
                              list={BOOKING_METHOD}
                            />
                          </div>
                        </div>
                        <div className='card-info-item card-inner card-height'>
                          <p className="editrate-text lable_spacing">Start Date <span className='text-danger fw-bold'>*</span></p>
                          <DatePicker
                            className='form-control addVehicleSelect input_feild'
                            clearIcon={null}
                            name="bookingDate"
                            format="MM/dd/yyyy"
                            value={bd}
                            onChange={date => bookingDateChange(date)}
                            minDate={new Date()}
                          />
                        </div>
                        <div className='card-info-item card-inner card-height'>
                          <p className="editrate-text lable_spacing">End Date <span className='text-danger fw-bold'>*</span></p>
                          <DatePicker
                            className='form-control addVehicleSelect input_feild'
                            clearIcon={null}
                            name="bookingEndDate"
                            disabled
                            format="MM/dd/yyyy"
                            value={bEndDate}
                          />
                        </div>
                        <div className='card-info-item card-inner'>
                          <p className="editrate-text lable_spacing">Start Time <span className='text-danger fw-bold'>*</span></p>
                          <TimePicker
                              showSecond={false}
                              defaultValue={bt}
                              value={bt}
                              className="time-picker-rc"
                              minuteStep={15}
                              format={'h:mm a'}
                              use12Hours
                              inputReadOnly
                              clearIcon={false}
                              onChange={time => bookingTimeChange(time)}
                              disabled={_.get(formData, `bookingMethod`, "") !== "hourly"}
                            />
                        </div>
                        <div className='card-info-item card-inner'>
                          <p className="editrate-text lable_spacing">End Time <span className='text-danger fw-bold'>*</span></p>
                          <input
                            class="form-control addVehicleSelect editratelable"
                            type="time"
                            placeholder="Enter end time..."
                            name="bookingEndTime"
                            value={moment(bEndTime).format("HH:mm")}
                            disabled
                          />
                        </div>
                      </div>
                      <Button
                        className="mt-3 create-reservation"
                        icon="Edit"
                        isLight
                        color='dark'
                        onClick={() => updateReservation(id)}>
                        Update Reservation
                      </Button>
                    </>
                  }
                </CardBody>
              </Card>
            </div>
          </div>
        </Page>
      </PageWrapper>

    </>
  )
}

export default BookMoke
