// FOR AUTHENTICATION
export const TOKEN_EXPIRED_MSG = "Authentication token has been expired! Please re-login to continue!"

// FOR US STATES
export const US_STATES = [
    {
      "abbreviation": "Alabama",
      "value": "Alabama",
      "text": "Alabama",
      "sortName": "AL"
    },
    {
      "abbreviation ": "Alaska",
      "value": "Alaska",
      "text": "Alaska",
      "sortName": "AK"
    },
    {
      "abbreviation ": "American Samoa",
      "value": "American Samoa",
      "text": "American Samoa",
      "sortName": "AS"
    },
    {
      "abbreviation ": "Arizona",
      "value": "Arizona",
      "text": "Arizona",
      "sortName": "AZ"
    },
    {
      "abbreviation ": "Arkansas",
      "value": "Arkansas",
      "text": "Arkansas",
      "sortName": "AR"
    },
    {
      "abbreviation ": "California",
      "value": "California",
      "text": "California",
      "sortName": "CA"
    },
    {
      "abbreviation ": "Colorado",
      "value": "Colorado",
      "text": "Colorado",
      "sortName": "CO"
    },
    {
      "abbreviation ": "Connecticut",
      "value": "Connecticut",
      "text": "Connecticut",
      "sortName": "CT"
    },
    {
      "abbreviation ": "Delaware",
      "value": "Delaware",
      "text": "Delaware",
      "sortName": "DE"
    },
    {
      "abbreviation ": "District Of Columbia",
      "value": "District Of Columbia",
      "text": "District Of Columbia",
      "sortName": "DC"
    },
    {
      "abbreviation ": "Federated States Of Micronesia",
      "value": "Federated States Of Micronesia",
      "text": "Federated States Of Micronesia",
      "sortName": "FM"
    },
    {
      "abbreviation ": "Florida",
      "value": "Florida",
      "text": "Florida",
      "sortName": "FL"
    },
    {
      "abbreviation ": "Georgia",
      "value": "Georgia",
      "text": "Georgia",
      "sortName": "GA"
    },
    {
      "abbreviation ": "Guam",
      "value": "Guam",
      "text": "Guam",
      "sortName": "GU"
    },
    {
      "abbreviation ": "Hawaii",
      "value": "Hawaii",
      "text": "Hawaii",
      "sortName": "HI"
    },
    {
      "abbreviation ": "Idaho",
      "value": "Idaho",
      "text": "Idaho",
      "sortName": "ID"
    },
    {
      "abbreviation ": "Illinois",
      "value": "Illinois",
      "text": "Illinois",
      "sortName": "IL"
    },
    {
      "abbreviation ": "Indiana",
      "value": "Indiana",
      "text": "Indiana",
      "sortName": "IN"
    },
    {
      "abbreviation ": "Iowa",
      "value": "Iowa",
      "text": "Iowa",
      "sortName": "IA"
    },
    {
      "abbreviation ": "Kansas",
      "value": "Kansas",
      "text": "Kansas",
      "sortName": "KS"
    },
    {
      "abbreviation ": "Kentucky",
      "value": "Kentucky",
      "text": "Kentucky",
      "sortName": "KY"
    },
    {
      "abbreviation ": "Louisiana",
      "value": "Louisiana",
      "text": "Louisiana",
      "sortName": "LA"
    },
    {
      "abbreviation ": "Maine",
      "value": "Maine",
      "text": "Maine",
      "sortName": "ME"
    },
    {
      "abbreviation ": "Marshall Islands",
      "value": "Marshall Islands",
      "text": "Marshall Islands",
      "sortName": "MH"
    },
    {
      "abbreviation ": "Maryland",
      "value": "Maryland",
      "text": "Maryland",
      "sortName": "MD"
    },
    {
      "abbreviation ": "Massachusetts",
      "value": "Massachusetts",
      "text": "Massachusetts",
      "sortName": "MA"
    },
    {
      "abbreviation ": "Michigan",
      "value": "Michigan",
      "text": "Michigan",
      "sortName": "MI"
    },
    {
      "abbreviation ": "Minnesota",
      "value": "Minnesota",
      "text": "Minnesota",
      "sortName": "MN"
    },
    {
      "abbreviation ": "Mississippi",
      "value": "Mississippi",
      "text": "Mississippi",
      "sortName": "MS"
    },
    {
      "abbreviation ": "Missouri",
      "value": "Missouri",
      "text": "Missouri",
      "sortName": "MO"
    },
    {
      "abbreviation ": "Montana",
      "value": "Montana",
      "text": "Montana",
      "sortName": "MT"
    },
    {
      "abbreviation ": "Nebraska",
      "value": "Nebraska",
      "text": "Nebraska",
      "sortName": "NE"
    },
    {
      "abbreviation ": "Nevada",
      "value": "Nevada",
      "text": "Nevada",
      "sortName": "NV"
    },
    {
      "abbreviation ": "New Hampshire",
      "value": "New Hampshire",
      "text": "New Hampshire",
      "sortName": "NH"
    },
    {
      "abbreviation ": "New Jersey",
      "value": "New Jersey",
      "text": "New Jersey",
      "sortName": "NJ"
    },
    {
      "abbreviation ": "New Mexico",
      "value": "New Mexico",
      "text": "New Mexico",
      "sortName": "NM"
    },
    {
      "abbreviation ": "New York",
      "value": "New York",
      "text": "New York",
      "sortName": "NY"
    },
    {
      "abbreviation ": "North Carolina",
      "value": "North Carolina",
      "text": "North Carolina",
      "sortName": "NC"
    },
    {
      "abbreviation ": "North Dakota",
      "value": "North Dakota",
      "text": "North Dakota",
      "sortName": "ND"
    },
    {
      "abbreviation ": "Northern Mariana Islands",
      "value": "Northern Mariana Islands",
      "text": "Northern Mariana Islands",
      "sortName": "MP"
    },
    {
      "abbreviation ": "Ohio",
      "value": "Ohio",
      "text": "Ohio",
      "sortName": "OH"
    },
    {
      "abbreviation ": "Oklahoma",
      "value": "Oklahoma",
      "text": "Oklahoma",
      "sortName": "OK"
    },
    {
      "abbreviation ": "Oregon",
      "value": "Oregon",
      "text": "Oregon",
      "sortName": "OR"
    },
    {
      "abbreviation ": "Palau",
      "value": "Palau",
      "text": "Palau",
      "sortName": "PW"
    },
    {
      "abbreviation ": "Pennsylvania",
      "value": "Pennsylvania",
      "text": "Pennsylvania",
      "sortName": "PA"
    },
    {
      "abbreviation ": "Puerto Rico",
      "value": "Puerto Rico",
      "text": "Puerto Rico",
      "sortName": "PR"
    },
    {
      "abbreviation ": "Rhode Island",
      "value": "Rhode Island",
      "text": "Rhode Island",
      "sortName": "RI"
    },
    {
      "abbreviation ": "South Carolina",
      "value": "South Carolina",
      "text": "South Carolina",
      "sortName": "SC"
    },
    {
      "abbreviation ": "South Dakota",
      "value": "South Dakota",
      "text": "South Dakota",
      "sortName": "SD"
    },
    {
      "abbreviation ": "Tennessee",
      "value": "Tennessee",
      "text": "Tennessee",
      "sortName": "TN"
    },
    {
      "abbreviation ": "Texas",
      "value": "Texas",
      "text": "Texas",
      "sortName": "TX"
    },
    {
      "abbreviation ": "Utah",
      "value": "Utah",
      "text": "Utah",
      "sortName": "UT"
    },
    {
      "abbreviation ": "Vermont",
      "value": "Vermont",
      "text": "Vermont",
      "sortName": "VT"
    },
    {
      "abbreviation ": "Virgin Islands",
      "value": "Virgin Islands",
      "text": "Virgin Islands",
      "sortName": "VI"
    },
    {
      "abbreviation ": "Virginia",
      "value": "Virginia",
      "text": "Virginia",
      "sortName": "VA"
    },
    {
      "abbreviation ": "Washington",
      "value": "Washington",
      "text": "Washington",
      "sortName": "WA"
    },
    {
      "abbreviation ": "West Virginia",
      "value": "West Virginia",
      "text": "West Virginia",
      "sortName": "WV"
    },
    {
      "abbreviation ": "Wisconsin",
      "value": "Wisconsin",
      "text": "Wisconsin",
      "sortName": "WI"
    },
    {
      "abbreviation ": "Wyoming",
      "value": "Wyoming",
      "text": "Wyoming",
      "sortName": "WY"
    }
  ]

// FOR USER ROLE 
export const USER_ROLE = {
  ADMIN: "admin",
  SUPER_ADMIN: "superAdmin",
  PARTNER: "partner"
}
  